export default {

    activeZone: null,
    showConfirm: false,
    finished: false,
    properyAlert: null,
    adviceKey: null,
    restored: false,
    data: {
        location: null,
        property: null,
        power: null,
        amps: null,
        ampsAvailable: 0,
        absence: false,
        approval: false,
        municipality: false,
        speed2080: 0,
        speed0100: 0,
    },

    init() {
        this.restore();

        window.addEventListener('lpFinished', (e) => {
            console.log(e.detail);
        });

        this.$nextTick(() => {
            this.activeZone = 'location';
        });

        this.$watch('data.location', val => {
            this.showConfirm = false;
            this.finished = false;

            if(val == 'public') {
                this.data.property = null;
                this.data.power = null;
                this.data.amps = null;
            } else {
                this.scrollTo(this.$refs.property);
            }
        });

        this.$watch('data.property', val => {
            this.scrollTo(this.$refs.power);
            this.showConfirm = false;
            this.finished = false;
        });

        this.$watch('data.power', val => {
            this.scrollTo(this.$refs.amps);
            this.showConfirm = false;
            this.finished = false;
            this.data.amps = null;
        });

        this.$watch('data.amps', val => {
            this.scrollTo(this.$refs.confirm);
            this.finished = false;

            if (val) {
                this.showConfirm = true;
            }
        });

        this.$watch('finished', (val, oldVal) => {
            if (!val) {
                this.adviceKey = null;

                window.history.pushState({}, null, '/' + window.language);

                return;
            }
            this.$nextTick(() => {
                this.calculateVerdict();
                this.calculateAmpsAvailable();

                if (val === true) {
                    this.store();
                }

                setTimeout(() => {
                    this.scrollTo(this.$refs.verdict, false);
                }, 100);

                window.history.pushState({}, null, '/' + window.language + '/results');
            });
        });
    },

    calculateVerdict() {
        if ([ 'garage', 'driveway' ].includes(this.data.location) && [ 'my' ].includes(this.data.property) && [ '1-phase-230-plus-n', '1-phase-400-plus-n', '3-phase-400-plus-n' ].includes(this.data.power)) {
            this.adviceKey = 'advice1';
        }
        if ([ 'garage', 'driveway' ].includes(this.data.location) && [ 'my' ].includes(this.data.property) && [ '2-phase-230', '3-phase-230' ].includes(this.data.power)) {
            this.adviceKey = 'advice2';
            this.data.absence = true;
        }
        if ([ 'garage', 'driveway' ].includes(this.data.location) && [ 'rent', 'assoc' ].includes(this.data.property) && [ '1-phase-230-plus-n', '1-phase-400-plus-n', '3-phase-400-plus-n' ].includes(this.data.power)) {
            this.adviceKey = 'advice3';
            this.data.approval = true;
        }
        if ([ 'garage', 'driveway' ].includes(this.data.location) && [ 'rent', 'assoc' ].includes(this.data.property) && [ '2-phase-230', '3-phase-230' ].includes(this.data.power)) {
            this.adviceKey = 'advice4';
            this.data.absence = true;
            this.data.approval = true;
        }
        if ([ 'non-adjacent' ].includes(this.data.location) && [ 'rent', 'assoc' ].includes(this.data.property) && [ '1-phase-230-plus-n', '1-phase-400-plus-n', '3-phase-400-plus-n' ].includes(this.data.power)) {
            this.adviceKey = 'advice5';
            this.data.approval = true;
            this.data.municipality = true;
        }
        if ([ 'non-adjacent' ].includes(this.data.location) && [ 'rent', 'assoc' ].includes(this.data.property) && [ '2-phase-230', '3-phase-230' ].includes(this.data.power)) {
            this.adviceKey = 'advice6';
            this.data.absence = true;
            this.data.approval = true;
            this.data.municipality = true;
        }
        if ([ 'non-adjacent' ].includes(this.data.location) && [ 'my' ].includes(this.data.property) && [ '1-phase-230-plus-n', '1-phase-400-plus-n', '3-phase-400-plus-n' ].includes(this.data.power)) {
            this.adviceKey = 'advice7';
        }
        if ([ 'non-adjacent' ].includes(this.data.location) && [ 'my' ].includes(this.data.property) && [ '2-phase-230', '3-phase-230' ].includes(this.data.power)) {
            this.adviceKey = 'advice8';
            this.data.absence = true;
        }
    },

    calculateAmpsAvailable() {
        const ampValue = parseInt(this.data.amps);
        let value = 0;

        if ([ '1-phase-230-plus-n' ].includes(this.data.power)) {
            value = (ampValue*230);
        }

        if ([ '1-phase-400-plus-n' ].includes(this.data.power)) {
            value = (ampValue*400);
        }

        if ([ '2-phase-230' ].includes(this.data.power)) {
            value = (ampValue*230) * Math.sqrt(2);
        }

        if ([ '3-phase-230' ].includes(this.data.power)) {
            value = (ampValue*230) * Math.sqrt(3);
        }

        if ([ '3-phase-400-plus-n' ].includes(this.data.power)) {
            value = (ampValue*400) * Math.sqrt(3);
        }

        this.data.ampsAvailable = (value / 1000).toFixed(1);

        switch (this.data.ampsAvailable) {
            case '5.2':
                this.data.speed2080 = '7h27';
                this.data.speed0100 = '12h29m';
                break;
            case '6.4':
                this.data.speed2080 = '6h02m';
                this.data.speed0100 = '10h06m';
                break;
            case '7.4':
                this.data.speed2080 = '5h15m';
                this.data.speed0100 = '8h45m';
                break;
            case '8.1':
                this.data.speed2080 = '4h48m';
                this.data.speed0100 = '8h';
                break;
            case '9.2':
                this.data.speed2080 = '4h10m';
                this.data.speed0100 = '7h';
                break;
            case '10.0':
                this.data.speed2080 = '3h45m';
                this.data.speed0100 = '6h28m';
                break;
            case '10.4':
                this.data.speed2080 = '3h44m';
                this.data.speed0100 = '6h13m';
                break;
            default:
                // 11 or more
                this.data.speed2080 = '3h35m';
                this.data.speed0100 = '5h50m';
                break;
        }

        if (window.language == 'nl') {
            this.data.speed2080 = this.data.speed2080.replace('h', 'u');
            this.data.speed0100 = this.data.speed0100.replace('h', 'u');
        }
    },

    restore() {
        const dataString = localStorage.getItem('lpData');

        if (dataString) {
            const dataObj = JSON.parse(dataString);

            if (!dataObj.amps) {
                return;
            }

            this.data = dataObj;
            this.restored = true;

            this.$nextTick(() => {
                this.finished = true;
            });
        }
    },

    store() {
        localStorage.setItem('lpData', JSON.stringify(this.data));

        if (!this.restored) {
            const customevent = new CustomEvent('lpFinished', {
                bubbles: true,
                detail: this.data
            });

            window.dispatchEvent(customevent);
        }
    },

    reset() {
        this.data.location = null;
        this.data.property = null;
        this.data.power = null;
        this.data.amps = null;
        this.data.ampsAvailable = null;
        this.data.absence = false;
        this.data.approval = false;
        this.data.municipality = false;
        this.data.speed2080 = null;
        this.data.speed0100 = null;
        this.finished = false;
        this.restored = false;

        window.history.pushState({}, null, '/' + window.language);

        this.$nextTick(() => {
            localStorage.removeItem('lpData');
            this.scrollTo(this.$refs.location);
        });
    },

    finish() {
        this.showConfirm = false;
        this.finished = true;
    },

    scrollTo(el, smooth) {
        let isSmooth = smooth ? 'smooth' : 'auto';

        this.$nextTick(() => {
            const frameH = window.innerHeight;
            const frameW = window.innerWidth;

            let offsetIncrement = frameH * 0.1;

            if (frameW > frameW) {
                offsetIncrement = frameH * 0.2;
            }

            const offset = -Math.abs(this.$refs.stickyregion.getBoundingClientRect().height) - offsetIncrement;
            const y = el.getBoundingClientRect().top + window.pageYOffset + offset;

            window.scrollTo({
                top: y,
                behavior: isSmooth
            });

            this.activeZone = el.getAttribute('x-ref');
        });
    },

    getLabel(key) {
        if (window.appLabels && window.appLabels.hasOwnProperty(key)) {
            return window.appLabels[key];
        }

        return key;
    }

};
